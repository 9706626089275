var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _vm.reMountedForm
        ? _c(
            "a-form-model",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "设备编号", prop: "deviceId" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.readOnly,
                      placeholder: "请输入设备编号",
                    },
                    model: {
                      value: _vm.form.deviceId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deviceId", $$v)
                      },
                      expression: "form.deviceId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "设备时间", prop: "deviceTime" } },
                [
                  _c("a-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: _vm.readOnly,
                      "value-format": "YYYY-MM-DD HH:mm:ss",
                      format: "YYYY-MM-DD HH:mm:ss",
                      "allow-clear": "",
                    },
                    model: {
                      value: _vm.form.deviceTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deviceTime", $$v)
                      },
                      expression: "form.deviceTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "当前状态的sim卡号", prop: "simNo" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.readOnly,
                      placeholder: "请输入当前状态的sim卡号",
                    },
                    model: {
                      value: _vm.form.simNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "simNo", $$v)
                      },
                      expression: "form.simNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "当前状态的车牌号", prop: "plateNo" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "show-search": "",
                        allowClear: "",
                        placeholder: "请输入当前状态的车牌号模糊查找",
                        "default-active-first-option": false,
                        "show-arrow": false,
                        "filter-option": false,
                        "not-found-content": null,
                        loading: _vm.loading,
                        disabled: _vm.readOnly,
                      },
                      on: {
                        search: _vm.handleLicensePlateNumSearch,
                        change: _vm.handleLicensePlateNumChange,
                      },
                      model: {
                        value: _vm.form.plateNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "plateNo", $$v)
                        },
                        expression: "form.plateNo",
                      },
                    },
                    _vm._l(_vm.vehicleNoArray, function (d) {
                      return _c(
                        "a-select-option",
                        {
                          key: d.licensePlateNum,
                          attrs: { value: d.licensePlateNum },
                        },
                        [_vm._v(" " + _vm._s(d.licensePlateNum) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "GPS经度", prop: "longitude" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.readOnly,
                      placeholder: "请输入GPS经度",
                    },
                    model: {
                      value: _vm.form.longitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "longitude", $$v)
                      },
                      expression: "form.longitude",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "GPS纬度", prop: "latitude" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.readOnly,
                      placeholder: "请输入GPS纬度",
                    },
                    model: {
                      value: _vm.form.latitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "latitude", $$v)
                      },
                      expression: "form.latitude",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "高程(米)", prop: "altitude" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.readOnly,
                      placeholder: "请输入高程(米)",
                    },
                    model: {
                      value: _vm.form.altitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "altitude", $$v)
                      },
                      expression: "form.altitude",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "速度(1/10公里每小时)", prop: "speed" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.readOnly,
                      placeholder: "请输入速度(1/10公里每小时)",
                    },
                    model: {
                      value: _vm.form.speed,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "speed", $$v)
                      },
                      expression: "form.speed",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "方向", prop: "direction" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.readOnly,
                      placeholder: "请输入方向",
                    },
                    model: {
                      value: _vm.form.direction,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "direction", $$v)
                      },
                      expression: "form.direction",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bottom-control" },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.saveLoading },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "dashed" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }