/*
 * @Auth: linjituan
 * @Date: 2021-09-29 19:13:03
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-30 10:02:05
 */
import request from '@/utils/request'

// 查询车辆台账列表
export function listVehicleAccount(query) {
  return request({
    url: '/iot/vehicleAccount/list',
    method: 'get',
    params: query
  })
}

// 查询车辆台账详细
export function getVehicleAccount(id) {
  return request({
    url: '/iot/vehicleAccount/' + id,
    method: 'get'
  })
}

// 新增车辆台账
export function addVehicleAccount(data) {
  return request({
    url: '/iot/vehicleAccount',
    method: 'post',
    data: data
  })
}

// 修改车辆台账
export function updateVehicleAccount(data) {
  return request({
    url: '/iot/vehicleAccount',
    method: 'put',
    data: data
  })
}

// 删除车辆台账
export function delVehicleAccount(id) {
  return request({
    url: '/iot/vehicleAccount/' + id,
    method: 'delete'
  })
}

// 导出车辆台账
export function exportVehicleAccount(query) {
  return request({
    url: '/iot/vehicleAccount/export',
    method: 'get',
    params: query
  })
}

// 下载用户导入模板
export function importTemplate() {
  return request({
    url: '/iot/vehicleAccount/importTemplate',
    method: 'get'
  })
}

// 导入用户
export function importData(data) {
  return request({
    url: '/iot/vehicleAccount/importData',
    method: 'post',
    data: data
  })
}
// 查找车牌号或自编号
export function searchCarNo(query) {
  return request({
    url: '/iot/vehicleAccount/searchCarNo',
    method: 'post',
    params: query
  })
}

// 获取部门下拉树及人员设备
export function vehicleAccountTree() {
  return request({
    url: '/iot/vehicleAccount/tree',
    method: 'get'
  })
}
